import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from '@material-ui/core';

// third-party
import clsx from 'clsx';

// project imports
import Breadcrumbs from './../../ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from './../../menu-items';
import { drawerWidth } from '../../store/constant';
import { SET_MENU } from './../../store/actions';
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../../store/actions';

import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom'
// assets
import { IconChevronRight } from '@tabler/icons';
import Customization from '../Customization';
import ChatBox from '../../menu-items/Dashboard/Leads/ChatBox';
import { dialerState, modeState, storeId } from '../../action/dialerAction';
import { getDecryptDataFromLocal, setencryptDataInLocal } from '../../utils/encrypt';

var timeToken = localStorage.getItem("tokenTime")

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        backgroundColor: theme.palette.background.default
    },
    appBarWidth: {
        transition: theme.transitions.create('width'),
        backgroundColor: theme.palette.background.default
    },
    content: {
        ...theme.typography.mainContent,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: '0px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '0px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '0px',
            marginRight: '10px'
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//




const MainLayout = ({ children }) => {
    let role = getDecryptDataFromLocal("setRole")
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    let intervalRef = useRef(null)
    const [leads, setLeads] = useState([])
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => dispatch({ type: SET_MENU, opened: !leftDrawerOpened });;
    const followUpsList = useSelector((store) => store.salesleadstore.leads)
    const followUpRef = useRef()
    const FOLLOWUP_LIST_REFRESH_TIME = 1000 * 60 * 5

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const setSession = (serviceToken) => {
        if (serviceToken) {
            localStorage.setItem('serviceToken', serviceToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${serviceToken}`;
            axios.interceptors.response.use(response => response, error => {

                if (error?.response?.status === 403) {
                    localStorage.removeItem('serviceToken');
                    localStorage.setItem('url', location.pathname)
                    delete axios.defaults.headers.common["Authorization"];
                    window.location.reload()
                } else {
                    return Promise.reject(error);
                }
            })
        } else {
            localStorage.removeItem('serviceToken');
            delete axios.defaults.headers.common["Authorization"];
            sessionStorage.setItem('url', location.pathname)
            window.location.reload()
        }
    };


    const refreshToken = () => {
        if (localStorage.getItem("serviceToken")) {
            axios.get(process.env.REACT_APP_PATH + "/admin/refreshtoken", { headers: { "Authorization": `Bearer ${localStorage.getItem("serviceToken")}` } })
                .then((res) => {
                    if (res.status === 200) {
                        setSession(res.data.data.refreshToken);
                        setencryptDataInLocal('setRole', res.data.data.role);
                        dispatch({ type: LOGIN, });
                    }
                    else {
                        dispatch({ type: LOGOUT, });
                        dispatch(modeState('Manual'));
                        dispatch(dialerState('2'));
                        clearInterval(intervalRef.current)
                        sessionStorage.setItem('url', location.pathname)
                        window.location.reload()
                        dispatch(storeId(''))
                        dispatch(dialerState('2'));
                    }


                }).catch((err) => {
                    console.log("err", err)
                    localStorage.removeItem('serviceToken');
                    delete axios.defaults.headers.common["Authorization"];
                    sessionStorage.setItem('url', location.pathname)
                    window.location.reload()
                    clearInterval(intervalRef.current)
                    dispatch({
                        type: LOGOUT,
                    });
                    dispatch(storeId(''));
                    dispatch(dialerState('2'));
                })
        } else {
            localStorage.removeItem('serviceToken');
            sessionStorage.setItem('url', location.pathname);
            dispatch(storeId(''))
            delete axios.defaults.headers.common["Authorization"];
            window.location.reload()
            clearInterval(intervalRef.current)
            dispatch({
                type: LOGOUT,
            });
            dispatch(storeId(''));
            dispatch(dialerState('2'));
        }
    }
    const followUpsFunction = () => {
        axios.get(process.env.REACT_APP_PATH + '/leads/getUpcomingFollowUps').then((res) => {
            if (res.data.success && res.data.message == undefined) {
                let leadsUpdate = res.data.data.map((cur) => {
                    let temp = cur;
                    temp.show = true
                    return temp
                })
                dispatch({
                    type: "SALES_LEADS_ADD",
                    payload: leadsUpdate
                })
                setLeads(leadsUpdate)
            } else if (res.data.success && res.data.message.length > 0) {
                dispatch({
                    type: "SALES_LEADS_ADD",
                    payload: []
                })
                setLeads([])
            }

        }).catch((error) => console.log('error', error))
    }


    useEffect(() => {
        followUpRef.current = setInterval(() => {
            if (role == 5) {
                followUpsFunction()
            }
        }, FOLLOWUP_LIST_REFRESH_TIME)
        setTimeout(() => {
            if (role == 5) {
                followUpsFunction()
            }
        }, 2000)
        return (() => {
            clearInterval(followUpRef.current)
        })
    }, [])


    useEffect(() => {
        let expTime = parseJwt(localStorage.getItem("serviceToken"))
        let tokenRefreshTime = (expTime?.exp * 1000 - Date.now() - 1000 * 60 * 3)

        intervalRef.current = localStorage.getItem("serviceToken") && setInterval(() => {
            refreshToken()
        }, (tokenRefreshTime))

        return (() => {
            console.log("clearTimeout(intervalRef.current)clearTimeout(intervalRef.current)")
        })
    }, [])


    useEffect(() => {
        const openLeftDrawerState = (val) => {
            dispatch({ type: SET_MENU, opened: val });
        };
        openLeftDrawerState(matchUpMd);
    }, [dispatch, matchUpMd]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* header */}
            <AppBar position="fixed" color="inherit" elevation={0} className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}>
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <main
                className={clsx([
                    classes.content,
                    {
                        [classes.contentShift]: leftDrawerOpened
                    }
                ])}
            >
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <div>{children}</div>
            </main>
            {/* <Customization /> */}

            <div style={{ position: 'fixed', bottom: 0, right: 100, display: 'flex', zIndex: 100, alignItems: "flex-end", }}>
                {
                    followUpsList && followUpsList.map((lead, index) => {
                        return (
                            <ChatBox lead={lead} index={index} />
                        )
                    })
                }
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
