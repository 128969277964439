import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function AppointmentDetails() {
  const { appointmentId } = useParams();
  const [allAppointmentData, setAllAppointmentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAppointmentDetails = () => {
    axios
      .get(
        process.env.REACT_APP_PATH +
          "/appointments/getAppointmentById/" +
          appointmentId
      )
      .then((res) => {
        if (res.data.success) {
          setAllAppointmentData(res.data.data || []);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
      });
  };

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  return (
    <SubCard title={"Salon Details"}>
      <Grid container xl={12} spacing={2}>
        <Grid item lg={6} md={6} sm={12}>
          <SubCard title={"Salon Details"}>
            <table>
              <tr>
                <td>Name :</td>
                <td>{allAppointmentData?.sellerName}</td>
              </tr>
              <tr>
                <td>Total Amount :</td>
                <td>{allAppointmentData?.payableAmount}</td>
              </tr>
            </table>
          </SubCard>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
            {
                allAppointmentData?.customer &&    <SubCard title={"Customer Details Details"}>
                <table>
                  <tr>
                    <td>Name :</td>
                    <td>{allAppointmentData?.customer?.name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number :</td>
                    <td>{allAppointmentData?.customer?.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td>Address 1 :</td>
                    <td>{allAppointmentData?.customer?.address?.address1}</td>
                  </tr>
                  <tr>
                    <td>Address 2 :</td>
                    <td>{allAppointmentData?.customer?.address?.address2}</td>
                  </tr>
                  <tr>
                    <td>Landmark :</td>
                    <td>{allAppointmentData?.customer?.address?.landmark}</td>
                  </tr>
                  <tr>
                    <td>City :</td>
                    <td>{allAppointmentData?.customer?.address?.city}</td>
                  </tr>
                  <tr>
                    <td>State :</td>
                    <td>{allAppointmentData?.customer?.address?.state}</td>
                  </tr>
                  <tr>
                    <td>PostalCode :</td>
                    <td>{allAppointmentData?.customer?.address?.postalCode}</td>
                  </tr>
                </table>
                {/* <Typography>Name : {allAppointmentData?.customer?.name}</Typography> */}
                {/* <Typography>phoneNumber : {allAppointmentData?.customer?.phoneNumber}</Typography>
                <Typography>address 1: {allAppointmentData?.customer?.address?.address1}</Typography>
                <Typography>address 2: {allAppointmentData?.customer?.address?.address2}</Typography>
                <Typography>landmark: {allAppointmentData?.customer?.address?.landmark}</Typography>
                <Typography>city: {allAppointmentData?.customer?.address?.city}</Typography>
                <Typography>state: {allAppointmentData?.customer?.address?.state}</Typography>
                <Typography>postalCode: {allAppointmentData?.customer?.address?.postalCode}</Typography> */}
              </SubCard>
            }
       
        </Grid>
      </Grid>
      <SubCard title={"Service"} sx={{marginTop:2}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAppointmentData?.services &&
              allAppointmentData?.services.length > 0 &&
              allAppointmentData?.services?.map((service) => {
                return (
                  <TableRow>
                    <TableCell>{service?.name}</TableCell>
                    <TableCell>{service?.price}</TableCell>
                    <TableCell>{service?.quantity}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </SubCard>
    </SubCard>
  );
}

export default AppointmentDetails;
