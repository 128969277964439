import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// material-ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button } from '@material-ui/core';
import {
  CardContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  // Checkbox,
  Select,
  // ListItemText,
  FormControl,
  // MenuItem,
  // InputLabel,
  // Input,
  Button,
  Paper,
  // Tab
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
// project imports
import MainCard from "../../ui-component/cards/MainCard";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Switch from "@mui/material/Switch";

import axios from "axios";
// import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CancellationAlert from "./CancellationAlert";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
// table columns
// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
// const showStatus = ["Processing", 'Canceled', "Deliverd", "In Transit"]

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  // const handleFirstPageButtonClick = event => {
  //     onPageChange(event, 0);
  // };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, true);
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} aria-label="Previous Day">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//-----------------------|| TABLE - STICKY HEADER ||-----------------------//

export default function OnlineBulkUnverifiedOrder() {
 
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [orderdata, setOrderData] = useState([]);
  const [search, setSearch] = React.useState("");
   const [pageLoad, togglePageLoad] = useState(false);
  const [totalpage, setTotalPage] = useState();
  const role = getDecryptDataFromLocal("setRole");
  const [statusFilter, setStatusFilter] = useState("");
  const [recentDay, setRecentDay] = useState(0);
   

  const [shipSearch, setShipSearch] = useState("");

  const phoneNumber = getDecryptDataFromLocal("phoneNumber");

  const label = { inputProps: { "aria-label": "Switch demo" } };
   const ref = useRef(null);

  const accessNumber =  ["9719836187", '9911579990'];
  const dataLoad = async (search, page, rowsPerPage) => {
    try {
      var orderReq = {
        phoneNumber: search,
        page: page,
        pageLimit: rowsPerPage,
        onlineBulk:true
      };
      axios
        .post(
          process.env.REACT_APP_PATH + "/role1/getOfflineUnverifiedOrders",
          orderReq
        )
        .then(function (respo) {
          if (respo.data.success) {
            // console.log("response", respo)
            setOrderData(respo.data.data[0].data);
            setTotalPage(respo.data.data[0].metaData[0].totalDocuments);
            if (ref.current) {
              ref.current.scrollTop = 0;
            }
          } else {
            setOrderData([]);
          }
          togglePageLoad(true);
        })
        .catch(function (error) {
          console.log(error, "data not comeing");
          togglePageLoad(true);
        });
    } catch (error) {
      console.log(error, "api error");
      togglePageLoad(true);
    }
  };

  const handleSearch = (event) => {
    const newString = event.target.value;
    setSearch(newString);
    if (newString.length == 10) {
      dataLoad(event.target.value, "", rowsPerPage);
    } else if (newString.length == 0) {
      dataLoad(event.target.value, "", rowsPerPage);
    }
  };

  const orderSearchByShipId = (event) => {
    event.preventDefault();
    if (shipSearch != "") {
      axios
        .post(
          process.env.REACT_APP_PATH + "/role1/getOfflineUnverifiedOrders",
          {
            shiprocketOrderId: shipSearch.toUpperCase(),
            onlineBulk:true
          }
        )
        .then((res) => {
          if (res.data.success) {
            setOrderData(res.data.data[0].data);
            setTotalPage(res.data.data[0].metaData[0].totalDocuments);
            if (ref.current) {
              ref.current.scrollTop = 0;
            }
          } else {
            setOrderData(0);
          }
          togglePageLoad(true);
        })
        .catch((err) => {
          console.log("err", err);
          togglePageLoad(true);
        });
    }
  };

  const handleShipChange = (e) => {
    let searchString = e.target.value.trim();
    setShipSearch(searchString);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dataLoad(search, "", +event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(rowsPerPage);
    dataLoad(search, newPage, rowsPerPage);
  };

  const previousDayData = () => {
    setRecentDay((pre) => pre + 1);
    setPage(0);
    setRowsPerPage(rowsPerPage);
  };

  const getVerifyProduct = (id, isVerified) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/role4/verifyProductOrder`,
      data: {
        orderId: id,
        isVerified: isVerified,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (!isVerified) {
          } else {
            alert("Status Changed Successfully");
            window.location.reload();
          }
        } else {
          alert("Something went wrong !");
        }
      })
      .catch((err) => {
        alert("Something Went Wrong");
      });
  };

  const chip = (status) => {
    if (status === 1) {
      return <Chip label="Processing" size="small" chipcolor="primary" />;
    } else if (status === 2) {
      return <Chip label="Canceled" size="small" chipcolor="orange" />;
    } else if (status === 3) {
      return <Chip label="Complete" size="small" chipcolor="success" />;
    } else if (status === 4) {
      return <Chip label="RTO" size="small" chipcolor="orange" />;
    } else if (status === 6) {
      return <Chip label="Undelivered" size="small" chipcolor="orange" />;
    } else if (status == 8) {
      return <Chip label="Splitted" size="small" chipcolor="secondary" />;
    }
  };

  useEffect(() => {
    dataLoad(search, page, rowsPerPage, statusFilter);
  }, []);

  if (!pageLoad) {
    return (
      <Paper align="center">
        <img
          alt="Example Alt"
          style={{ width: "200px", height: "130px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
          align="center"
        />
      </Paper>
    );
  }

  const CheckOrder = (SelectedOrder) => {
    if (
      SelectedOrder.hasOwnProperty("isVerified") === false ||
      SelectedOrder.isVerified === true
    ) {
      // return <Switch {...label} onChange={() => getVerifyProduct(SelectedOrder._id, false)} />
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              false,
              SelectedOrder.source,
              SelectedOrder.phoneNumber
            )
          }
          checked={true}
          disabled={accessNumber.includes(phoneNumber) ? false : true}
        />
      );
    } else if (SelectedOrder.isVerified === false) {
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              true,
              SelectedOrder.source,
              SelectedOrder.phoneNumber
            )
          }
          checked={false}
          disabled={accessNumber.includes(phoneNumber) ? false : true}
        />
      );
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <SearchIcon fontSize="small" /> */}
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search Order By Number"
                value={search}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <form onSubmit={orderSearchByShipId} style={{ width: "100%" }}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={6} lg={8}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <SearchIcon fontSize="small" /> */}
                          </InputAdornment>
                        ),
                      }}
                      style={{ width: "100%" }}
                      onChange={handleShipChange}
                      placeholder="Search By Ship Rocket Id"
                      value={shipSearch}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={3} lg={2}>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>

          <Grid></Grid>
        </Grid>
      </CardContent>
      {/* table */}
      <TableContainer className={classes.container} ref={ref}>
        {orderdata.length > 0 ? (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* {columns.map((column) => (
                                <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))} */}

                <TableCell align="left">S.No.</TableCell>
                <TableCell align="left">Ship Order Id</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Phone Number</TableCell>
                <TableCell align="left">Total Amount</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell>Automatic Assigned</TableCell>
                <TableCell align="left">Action</TableCell>
                <TableCell align="center">Number Verified</TableCell>
                <TableCell align="left">Audit Verification</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                userSelect:
                  role == 1 || role == 2 || role == 4 ? "text" : "none",
              }}
            >
              {orderdata != null &&
                orderdata != undefined &&
                orderdata.length > 0
                ? orderdata
                  .map((order, id) => {
                    return (
                      <TableRow
                        sx={{ py: 3 }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={id}
                      >
                        <TableCell align="left">{id + 1}</TableCell>
                        <TableCell
                          align="left"
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          <Link
                            to={`/orderdetails/${order?._id}/false`}
                            style={{
                              textDecoration: "none",
                              color:
                                order?.paymentMethod == 1 ? "#000" : "green",
                            }}
                            target="_blank"
                          >
                            {order.shiprocketOrderId}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: order?.isBeuPartnerOrder ? "red" : "#000",
                          }}
                        >
                          {order.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: order?.isBeuPartnerOrder ? "red" : "#000",
                          }}
                        >
                          {order.phoneNumber}
                        </TableCell>
                        <TableCell align="left">
                          {Math.round(order?.totalPayableAmount || 0)}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 200 }}>
                          {new Date(order?.createdAt).toDateString()} -
                          {new Date(order?.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 150 }}>
                          {order.monthDiffFromFirstOrder >= 1
                            ? "Repeat Order"
                            : "New Order"}
                        </TableCell>
                        <TableCell align="left">
                          {/* {order.status} */}
                          {order?.status === 3 && chip(3)}
                          {order?.status === 2 && chip(2)}
                          {order?.status === 4 && chip(4)}
                          {order?.status === 6 && chip(6)}
                          {order?.status === 8 && chip(8)}
                          {order?.awbNumber !== undefined &&
                            order?.status === 1 ? (
                            <Chip
                              label="In Transit"
                              size="small"
                              chipcolor="primary"
                            />
                          ) : order.status === 1 &&
                            order.awbNumber === undefined ? (
                            chip(1)
                          ) : null}

                          {/* {order.status === 1 && (
                          <Chip
                            label="Processing"
                            size="small"
                            chipcolor="primary"
                          />
                        )} */}
                        </TableCell>
                        <TableCell>
                          {order?.isAutomaticAssigned &&
                            order?.shiprocketAssignResp
                            ? "Yes"
                            : order?.isAutomaticAssigned == false &&
                              order?.shiprocketAssignResp != undefined
                              ? Object.keys(order.shiprocketAssignResp).length >
                                0
                                ? "Not Assign"
                                : "No"
                              : "No"}
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            to={`/orderdetails/${order._id}/false`}
                            style={{
                              textDecoration: "none",
                              backgroundColor: "#f6f6f6",
                              padding: "10px 15px",
                            }}
                            target="_blank"
                          >
                            Details
                          </Link>
                        </TableCell>
                        <TableCell>
                          {(order?.deliveryNumberVerified === false ||
                            order?.deliveryNumberVerified === undefined ||
                            order?.deliveryNumberVerified == "") && (
                              <Chip
                                label="Not verified"
                                size="small"
                                chipcolor="orange"
                              />
                            )}
                          {order?.deliveryNumberVerified === true && (
                            <Chip
                              label="verified"
                              size="small"
                              chipcolor="success"
                            />
                          )}
                        </TableCell>
                        {accessNumber.includes(phoneNumber) ? (
                          <>
                            {/* <TableCell>{order?.isVerified ? <Button color="success" >Verified</Button> : <Button variant="contained" onClick={() => getVerifyProduct(order._id)}>Click</Button>}</TableCell> */}
                            <TableCell>
                              {/* {order["isVerified"] && order.isVerified === true ? <Switch {...label} onChange={() => getVerifyProduct(order._id, false)} defaultChecked /> : <Switch {...label} onChange={() => getVerifyProduct(order._id, false)} />} */}
                              {CheckOrder(order)}
                            </TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        ) : (
          <Grid item xs={12} style={{ width: "1000px" }}>
            <Typography variant="h4" padding="15px 0 0 15px">
              Orders Not Found
            </Typography>
          </Grid>
        )}
      </TableContainer>

      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={totalpage}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
