import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SubCard from "../../../ui-component/cards/SubCard";
import { CSVLink } from "react-csv";
import { Padding } from "@mui/icons-material";
import moment from "moment";

const headers = [
  { label: "Name", key: "name" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Agent Name", key: "agentName" },
];

function AllDownload() {
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    const getAllDownloadData = JSON.parse(
      localStorage.getItem("AllDownloadData")
    );
    setAllData(getAllDownloadData || []);
  }, []);
  console.log("allDa", allData.length);
  return (
    <SubCard
      title={"Seller Downloads By Agent"}
      secondary={
        
          <div style={{backgroundColor:'ButtonFace',padding:'5px 10px' ,}}>
            <CSVLink title="New Download" headers={headers} data={allData} filename="SellerDownloadByAgent.csv">
                Download
            </CSVLink>
          </div>
        
      }
    >
      <TableContainer>
        <Table stickyHeader style={{maxHeight:500}}>
          <TableHead>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Agent Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData &&
              allData.length > 0 &&
              allData.map((item) => (
                <TableRow>
                  <TableCell>{moment(item?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.phoneNumber}</TableCell>
                  <TableCell>{item?.agentName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubCard>
  );
}

export default AllDownload;
