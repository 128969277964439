import axios from "axios";
import React, { useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";

function BulkBrandWisePurchaseReport() {

    const [date, setDate] = useState({
        startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
        endDate: new Date().toISOString().slice(0, 10),
      });
      const [data, setdata] = React.useState([]);
      const [loading, setLoading] = useState(false);
      let [totalAchivement, setTotalAchivement] = useState(0);  


    const handleSearch = () => {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_PATH}/sales/branWiseSummaryForCrmBulkOrder`, {
            startDate: date.startDate,
            endDate: date.endDate,
          })
          .then((res) => {
            if (res.data.success) {
              let tempData = res.data.data;
              let total = 0;
              tempData.forEach((item) => {
                total += item.crmBulkRevenue;

              })
                setTotalAchivement(total);
              setdata(tempData);
              setLoading(false);
            } else {
              alert(res.data.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            alert(err?.response?.data?.message || "something went wrong");
            setLoading(false);
          });
      };
    
 
  return (
    <SubCard>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSearch()}
            fullWidth
            loading={loading}
            loadingIndicator="Loading..."
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer style={{ marginTop: 20, maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Achievement ({numericCommaSeparation(Math.round(totalAchivement||0))})</TableCell>
                <TableCell>Achievement %</TableCell>
                <TableCell>Margin</TableCell>
                <TableCell>Brand Wise Pendency</TableCell>
                <TableCell>Pending %</TableCell>
                <TableCell>Pending MRP</TableCell>
                <TableCell>Achieved MRP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                        <TableCell>{item.brandName}</TableCell>
                        <TableCell>{numericCommaSeparation(Math.round(item.target))}</TableCell>
                        <TableCell>{numericCommaSeparation( Math.round(item.crmBulkRevenue))}</TableCell>
                        <TableCell>{Math.round((item.crmBulkRevenue/ item.target)*100)}%</TableCell>
                        <TableCell>{numericCommaSeparation(Math.round( item.totalMargin))}</TableCell>
                        <TableCell>{numericCommaSeparation(Math.round(item.pendencyRevenue))}</TableCell>
                        <TableCell>{ Math.round(((item?.pendencyRevenue||0)/item.crmBulkRevenue)*100)}%</TableCell>
                        <TableCell>{numericCommaSeparation(Math.round(item.pendencyMrp))}</TableCell>
                        <TableCell>{numericCommaSeparation( Math.round(item.crmBulkRevenueMrp))}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </SubCard>
  );
}

export default BulkBrandWisePurchaseReport;
