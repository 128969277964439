import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
  IconShoppingCart,
  IconClipboardList,
  IconHome
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
  IconShoppingCart: IconShoppingCart,
  IconClipboardList: IconClipboardList,
  IconHome: IconHome
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
const userName = getDecryptDataFromLocal('phoneNumber');
let orderList = [];
let assignList = [];
if (userName == 'FLORIDA') {
  orderList = [
    {
      id: "Pending Order",
      title: <FormattedMessage id="Pending Order" />,
      type: "item",
      url: "/vendor/pendingorders",
      breadcrumbs: true,
    },
    {
      id: "Delivered Orders",
      title: <FormattedMessage id="Delivered Orders" />,
      type: "item",
      url: "/vendor/assignorders",
      breadcrumbs: true,
    },
    {
      id: "Cancelled Orders",
      title: <FormattedMessage id="Cancelled Orders" />,
      type: "item",
      url: "/vendor/cancelled-orders",
      breadcrumbs: true,
    },
    {
      id: "Rto Orders",
      title: <FormattedMessage id="Rto Orders" />,
      type: "item",
      url: "/vendor/rto-orders",
      breadcrumbs: true,
    },
    {
      id: "InTransit Orders",
      title: <FormattedMessage id="InTransit Orders" />,
      type: "item",
      url: "/vendor/intransit-orders",
      breadcrumbs: true,
    },
    {
      id: "Remove Order Of FC",
      title: <FormattedMessage id="Remove Order Of Florida" />,
      type: "item",
      url: "/vendor/remove-order-of-fc",
      breadcrumbs: true,
    },
    {
      id: "Ship Product Detail",
      title: <FormattedMessage id="Ship Product Detail" />,
      type: "item",
      url: "/vendor/ship-product-detail",
      breadcrumbs: true,
    },
  ]
} else {
  orderList = [
    {
      id: "Pending Order",
      title: <FormattedMessage id="Pending Order" />,
      type: "item",
      url: "/vendor/pendingorders",
      breadcrumbs: true,
    },
    {
      id: "Delivered Orders",
      title: <FormattedMessage id="Delivered Orders" />,
      type: "item",
      url: "/vendor/assignorders",
      breadcrumbs: true,
    },
    {
      id: "Cancelled Orders",
      title: <FormattedMessage id="Cancelled Orders" />,
      type: "item",
      url: "/vendor/cancelled-orders",
      breadcrumbs: true,
    },
    {
      id: "Rto Orders",
      title: <FormattedMessage id="Rto Orders" />,
      type: "item",
      url: "/vendor/rto-orders",
      breadcrumbs: true,
    },
    {
      id: "InTransit Orders",
      title: <FormattedMessage id="InTransit Orders" />,
      type: "item",
      url: "/vendor/intransit-orders",
      breadcrumbs: true,
    },
  ]
}


if (userName == 'FLORIDA') {
  assignList = [

    {
      id: "Assign Order From Florida",
      title: <FormattedMessage id="Assign Order From Florida" />,
      type: "item",
      url: "/vendor/assign-order-from-florida",
      breadcrumbs: true,
    },

    {
      id: "Dispatched Order Of FC",
      title: <FormattedMessage id="Dispatched Order Of FC" />,
      type: "item",
      url: "/vendor/dispatch-order-of-fc",
      breadcrumbs: true,
    },
  ]
}

let childList = [];
if (userName == 'FLORIDA') {
  childList.push(
    {
      id: "Florida Dashboard",
      title: <FormattedMessage id="Florida Dashboard" />,
      type: "collapse",
      icon: icons["IconHome"],
      children: [
        {
          id: "Dashboard",
          title: <FormattedMessage id="Dashboard" />,
          type: "item",
          url: "/florida/dashboard",
          breadcrumbs: true,
        },
        {
          id: "Create QR",
          title: <FormattedMessage id="Create QR" />,
          type: "item",
          url: "/florida/create-qr",
          breadcrumbs: true,
        },
        {
          id: "Packer List",
          title: <FormattedMessage id="Packer List" />,
          type: "item",
          url: "/florida/packer-list",
          breadcrumbs: true,
        },
        {
          id: "Packer Order List",
          title: <FormattedMessage id="Packer Order List" />,
          type: "item",
          url: "/florida/packer-order-list",
          breadcrumbs: true,
        },
        {
          id: "Packer Non Crm Order List",
          title: <FormattedMessage id="Packer Non Crm Order List" />,
          type: "item",
          url: "/florida/packer-non-crm-order-list",
          breadcrumbs: true,
        },

      ]
    },
    {
      id: "Orders",
      title: <FormattedMessage id="Orders" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: orderList
    },
    {
      id: "Assigned Order From Florida",
      title: <FormattedMessage id="Assigned Order From Florida" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: assignList
    },
    {
      id: "Inventory",
      title: <FormattedMessage id="Inventory" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: [
        {
          id: "Inventory",
          title: <FormattedMessage id="Inventory" />,
          type: "item",
          url: "/vendor/inventory",
          breadcrumbs: true,
        },
        {
          id: "Add Incoming Orders",
          title: <FormattedMessage id="Add Incoming Orders" />,
          type: "item",
          url: "/vendor/add-incoming-orders",
          breadcrumbs: true,
        },
        {
          id: "Purchase Orders",
          title: <FormattedMessage id="Purchase Orders" />,
          type: "item",
          url: "/vendor/purchase-orders",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Complaints",
      title: <FormattedMessage id="Complaints" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Complaints",
          title: <FormattedMessage id="Complaints" />,
          type: "item",
          url: "/vendor/complaints",
          breadcrumbs: true,
        },
        {
          id: "Users Complaints",
          title: <FormattedMessage id="Users Complaints" />,
          type: "item",
          url: "/vendor/users-complaints",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Riders",
      title: <FormattedMessage id="Riders" />,
      type: "collapse",
      icon: icons["IconNfc"],
      children: [
        {
          id: "All Riders",
          title: <FormattedMessage id="All Riders" />,
          type: "item",
          url: "/vendor/all-riders",
          breadcrumbs: true,
        },
        {
          id: "Cash Collection",
          title: <FormattedMessage id="Cash Collection" />,
          type: "item",
          url: "/vendor/cash-collection",
          breadcrumbs: true,
        },
        {
          id: "Riders Settlement",
          title: <FormattedMessage id="Riders Settlement" />,
          type: "item",
          url: "/vendor/riders-settlement",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Place Order",
      title: <FormattedMessage id="Place Order" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: [
        {
          id: "Place Order",
          title: <FormattedMessage id="Place Order" />,
          type: "item",
          url: "/vendor/place-order",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Settlements",
      title: <FormattedMessage id="Settlements" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Settlement",
          title: <FormattedMessage id="Settlement" />,
          type: "item",
          url: "/vendor/settlement",
          breadcrumbs: true,
        },
        {
          id: "Settlement Report",
          title: <FormattedMessage id="Settlement Report" />,
          type: "item",
          url: "/vendor/settlement-report",
          breadcrumbs: true,
        },
        {
          id: "Order Wise Settlement Report",
          title: <FormattedMessage id="Order Wise Settlement Report" />,
          type: "item",
          url: "/vendor/order-wise-settlement-report",
          breadcrumbs: true,
      },
      ],
    },
  )
} else {
  childList.push(

    {
      id: "Orders",
      title: <FormattedMessage id="Orders" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: orderList
    },
    {
      id: "Assigned Order From Florida",
      title: <FormattedMessage id="Assigned Order From Florida" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: assignList
    },
    {
      id: "Inventory",
      title: <FormattedMessage id="Inventory" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: [
        {
          id: "Inventory",
          title: <FormattedMessage id="Inventory" />,
          type: "item",
          url: "/vendor/inventory",
          breadcrumbs: true,
        },
        {
          id: "Add Incoming Orders",
          title: <FormattedMessage id="Add Incoming Orders" />,
          type: "item",
          url: "/vendor/add-incoming-orders",
          breadcrumbs: true,
        },
        {
          id: "Purchase Orders",
          title: <FormattedMessage id="Purchase Orders" />,
          type: "item",
          url: "/vendor/purchase-orders",
          breadcrumbs: true,
        },
        // {
        //   id: "Fc Grn",
        //   title: <FormattedMessage id="Fc-Grn" />,
        //   type: "item",
        //   url: "/vendor/fc-grn",
        //   breadcrumbs: true,
        // }
      ],
    },
    {
      id: "Complaints",
      title: <FormattedMessage id="Complaints" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Complaints",
          title: <FormattedMessage id="Complaints" />,
          type: "item",
          url: "/vendor/complaints",
          breadcrumbs: true,
        },
        {
          id: "Users Complaints",
          title: <FormattedMessage id="Users Complaints" />,
          type: "item",
          url: "/vendor/users-complaints",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Riders",
      title: <FormattedMessage id="Riders" />,
      type: "collapse",
      icon: icons["IconNfc"],
      children: [
        {
          id: "All Riders",
          title: <FormattedMessage id="All Riders" />,
          type: "item",
          url: "/vendor/all-riders",
          breadcrumbs: true,
        },
        {
          id: "Cash Collection",
          title: <FormattedMessage id="Cash Collection" />,
          type: "item",
          url: "/vendor/cash-collection",
          breadcrumbs: true,
        },
        {
          id: "Riders Settlement",
          title: <FormattedMessage id="Riders Settlement" />,
          type: "item",
          url: "/vendor/riders-settlement",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Place Order",
      title: <FormattedMessage id="Place Order" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: [
        {
          id: "Place Order",
          title: <FormattedMessage id="Place Order" />,
          type: "item",
          url: "/vendor/place-order",
          breadcrumbs: true,
        }
      ],
    },
    {
      id: "Settlements",
      title: <FormattedMessage id="Settlements" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Settlement",
          title: <FormattedMessage id="Settlement" />,
          type: "item",
          url: "/vendor/settlement",
          breadcrumbs: true,
        },
        {
          id: "Settlement Report",
          title: <FormattedMessage id="Settlement Report" />,
          type: "item",
          url: "/vendor/settlement-report",
          breadcrumbs: true,
        },
        {
          id: "FC Product Wise Report",
          title: <FormattedMessage id="FC Product Wise Report" />,
          type: "item",
          url: "/vendor/fcproductWiseReport",
          breadcrumbs: true,
        },
        {
          id: "Order Wise Settlement Report",
          title: <FormattedMessage id="Order Wise Settlement Report" />,
          type: "item",
          url: "/vendor/order-wise-settlement-report",
          breadcrumbs: true,
      },
      ],
    })
}


export const vendor = {
  id: "Fullfillment Center",
  title: <FormattedMessage id="Fullfillment Center" />,
  type: "group",
  children: childList
};
