import React, { useEffect } from "react";
import SubCard from "../../../ui-component/cards/SubCard";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { set } from "date-fns";

function UpdateZohoCustomerId() {
  const [sellerPhoneNumber, setSellerPhoneNumber] = React.useState("");
  const [sellerDetails, setSellerDetails] = React.useState({});
  const [zohoCustomerId, setZohoCustomerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [bussinessName, setBussinessName] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [zohoCustomerId2, setZohoCustomerId2] = React.useState("");
  const [zohoCustomerId1, setZohoCustomerId1] = React.useState("");

  const searchSellerByPhone = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/role1/allSeller", {
        phoneNumber: sellerPhoneNumber,
        isCrmOrder: true,
      })

      .then((res) => {
        if (res.data.success) {
          let sellerData = res.data.data[0]?.data[0];
          setName(sellerData.name);
          setBussinessName(sellerData.businessName);
          setZohoCustomerId2(sellerData?.zohoCustomerId2 || "");
          setZohoCustomerId1(sellerData?.zohoCustomerId || "");
          setSellerDetails(sellerData);
        } else {
          alert(res.data.message || "Seller Not Found");
        }
      })
      .catch((err) => {
        console.log("error", err);
        alert("Something went wrong");
      });
  };

  const updateZohoCustomerId = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/sales/updateZohoCustomerId", {
        sellerId: sellerDetails._id,
        zohoCustomerId: zohoCustomerId,
        zohoOrganization: +organization,
        phoneNumber: sellerPhoneNumber,
      })
      .then((res) => {
        if (res.data.success) {
          alert("Zoho Customer Id Updated Successfully");
          //   window.location.reload();
          searchSellerByPhone();
          setOrganization("");
          setZohoCustomerId("");
        } else {
          alert(res.data.message || "Failed to update Zoho Customer Id");
        }
      })
      .catch((err) => {
        console.log("error", err);
        alert("Something went wrong");
      });
  };

  useEffect(() => {
    if (sellerPhoneNumber.length == 10) {
      searchSellerByPhone();
    }
  }, [sellerPhoneNumber]);
  return (
    <SubCard title={"Update Zoho Customer Id"}>
      <Grid container gap={2}>
        <Grid item sm={12} md={2}>
          <TextField
            label="Phone Number"
            value={sellerPhoneNumber}
            onChange={(e) => setSellerPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md={2}>
          <TextField label="Name" value={name} disabled fullWidth />
        </Grid>
        <Grid item sm={12} md={2}>
          <TextField
            label="Business Name"
            value={bussinessName}
            disabled
            fullWidth
          />
        </Grid>
        {zohoCustomerId1 && (
          <Grid item sm={12} md={2}>
            <TextField
              label="Emergent Zoho Customer Id"
              value={zohoCustomerId1}
              disabled
              fullWidth
            />
          </Grid>
        )}

        {zohoCustomerId2 && (
          <Grid item sm={12} md={2}>
            <TextField
              label="Florida Zoho Customer Id"
              value={zohoCustomerId2}
              disabled
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid container gap={2} mt={2}>
        <Grid item sm={12} md={3}>
          <TextField
            value={zohoCustomerId}
            label="Zoho Customer Id"
            onChange={(e) => setZohoCustomerId(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <Select
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            variant="outlined"
            fullWidth
            displayEmpty
          >
            <MenuItem value={""} disabled>
              <em>Select Organization</em>
            </MenuItem>
            <MenuItem value={1} disabled={zohoCustomerId1}>
              Emergent
            </MenuItem>
            <MenuItem value={2} disabled={zohoCustomerId2}>
              Flordia
            </MenuItem>
          </Select>
        </Grid>
        {sellerDetails._id && (
          <Grid item sm={12} md={3}>
            <Button
              variant="contained"
              onClick={updateZohoCustomerId}
              fullWidth
              sx={{ height: "100%" }}
              disabled={organization==''}
            >
              Submit
            </Button>
          </Grid>
        )}
      </Grid>
    </SubCard>
  );
}

export default UpdateZohoCustomerId;
