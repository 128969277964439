import React, { useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ApiInstance from "../../Services/ApiInstance";
import { decryptData, encryptData, getDecryptDataFromLocal, setencryptDataInLocal } from "../../utils/encrypt";

function SalonUserRegister() {
  const [sellerDetails, setSellerDetails] = useState({
    name: "",
    phoneNumber: "",
    referCode: "",
    refferdByAgenttype: "",
  });
  const sellerCreate = (e) => {
    e.preventDefault();
    console.log("sellerDetails", sellerDetails);
    if(sellerDetails.phoneNumber.length==10){
    ApiInstance.post("/seller/createNewSeller", {
      name: sellerDetails.name.trim(),
      phoneNumber: sellerDetails.phoneNumber.trim(),
      referedBy: sellerDetails.referCode,
      refferdByAgenttype: sellerDetails.refferdByAgenttype,
    })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Seller created succesfully");
          window.location.reload();
        } else {
          alert(res.data?.message || "Something went wrong");
        }
      })
      .catch((error) => {
        alert("Something went wrong");
      });
}else{
    alert('Invalid Phone Number')
}
  };



  const checkFun = ()=>{
    let da = getDecryptDataFromLocal('testing')
    console.log('daaa',da)
  }
  const checkFun2 = ()=>{
    let da = setencryptDataInLocal('testing',{
      name:'ajsbh',
      phone:299299292
    })
    console.log('daaa--',da)
  }






  return (
    <SubCard>
      <form onSubmit={sellerCreate}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12} md={4} gap={2}>
            <TextField
              id="outlined-basic2"
              fullWidth
              variant="outlined"
              label="Name"
              name="name"
              value={sellerDetails.name}
              required
              onChange={(t) => {
                setSellerDetails((pre) => ({
                  ...pre,
                  name: t.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic2"
              fullWidth
              variant="outlined"
              label="Phone Number"
              name="phoneNumber"
              type="tel"
              value={sellerDetails.phoneNumber}
              required
              onChange={(t) => {
                setSellerDetails((pre) => ({
                  ...pre,
                  phoneNumber: t.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic2"
              fullWidth
              variant="outlined"
              label="Refer Code"
              name="referCode"
              value={sellerDetails.referCode}
              required
              onChange={(t) => {
                setSellerDetails((pre) => ({
                  ...pre,
                  referCode: t.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              required
              value={sellerDetails.refferdByAgenttype}
              fullWidth
              displayEmpty
              onChange={(t) => {
                setSellerDetails((pre) => ({
                  ...pre,
                  refferdByAgenttype: t.target.value,
                }));
              }}
            >
              <MenuItem disabled value="">
                <em>Select Agent Type</em>
              </MenuItem>
              <MenuItem value={"ONLINE"}>ONLINE</MenuItem>
              <MenuItem value={"OFFLINE"}>OFFLINE</MenuItem>
              <MenuItem value={"BULK"}>BULK</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" type="submit" style={{ marginTop: 10 }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      <Button onClick={checkFun}>
        subbubs
      </Button>
      <Button onClick={checkFun2}>
        subbubs--
      </Button>
    </SubCard>
  );
}

export default SalonUserRegister;
