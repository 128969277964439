import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyTwoToneIcon from '@material-ui/icons/ContentCopyTwoTone';
import ContentCutTwoToneIcon from '@material-ui/icons/ContentCutTwoTone';
import LinkIcon from '@material-ui/icons/Link';
import SubCard from "../../../ui-component/cards/SubCard";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { SNACKBAR_OPEN } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";

function CreatePaymentLink() {
    const dispatch = useDispatch();

  const [orderId, setOrderId] = useState("");
  const [orderIdMongo, setOrderIdMongo] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [errorCode, setErrorCode] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    phoneNumber: "",
    name: "",
    totalPayableAmount: "",
    paymentMethod:''
  });
  const submitPress = () => {
    axios
      .get(process.env.REACT_APP_PATH+ "/products/getOrderById/"+orderId)
      .then((res) => {
        if (res.data.success && res.data.data) {
          setOrderDetails(res.data.data);
          setOrderIdMongo(res.data.data._id);
          setPaymentAmount(res.data.data.totalPayableAmount);
          if(res.data.data.paymentMethod != 1){
            alert("Payment is already done");
          }
        } else {
          alert(res.data.message || "Order not found");
        }
      })
      .catch((error) => {
        console.log("error of fetch order Id", error);
      });
  };

  const handleChangePaymentAmount = (e) => {
    let value = e.target.value;
    let tp = orderDetails.totalPayableAmount;
    let fivePercent = tp * 0.05;
    let Percent70 = tp * 0.7;

    if (value <= tp) {
      if (value < fivePercent || value < 100) {
        setErrorCode(
          `Payment amount should be greater than ${Math.ceil(
            fivePercent > 100 ? fivePercent : 100
          )}`
        );
      } else if (value > Percent70 && value < tp) {
        setErrorCode(
          `Payment shound be less than ${Math.ceil(
            Percent70
          )} or equal to ${tp}`
        );
      } else {
        setErrorCode(null);
      }
      setPaymentAmount(value);
    }
    // if(value)
  };

  const createLink = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/products/createPaymentLink", {
        amount: +paymentAmount,
        orderId:orderIdMongo ,
        phoneNumber: orderDetails.phoneNumber,
      })
      .then((res) => {
        if(res.data.success){
            console.log("res of create link", res.data);
            setLinkData(res.data.message);
        }else{
            alert(res.data.message || "Error in creating link");
        }
      })
      .catch((error) => {
        console.log("error of create link", error);
      });
  };

  return (
    <SubCard>
      <Grid container gap={2} alignItems={"center"}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            label="Order Id"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <Button
            onClick={submitPress}
            variant="contained"
            sx={{ marginLeft: 1, marginTop: 1 }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Grid container gap={2} mt={2}>
        <TextField value={orderDetails.name} label="Name" disabled />

        <TextField
          value={orderDetails.totalPayableAmount}
          label="Total Payable Amount"
          disabled
        />
        <TextField
          value={orderDetails.phoneNumber}
          label="Phone Number"
          onChange={(e) =>
            setOrderDetails({
              ...orderDetails,
              phoneNumber: e.target.value.trim(),
            })
          }
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
      {orderDetails.phoneNumber && orderDetails.phoneNumber.length == 10 && (
        <Grid container gap={2} mt={2} alignItems={"center"}>
          <Grid>
            <TextField
              value={paymentAmount}
              label="Enter Amount"
              onChange={handleChangePaymentAmount}
              helperText={errorCode}
              error={errorCode == null ? false : true}
            />
          </Grid>
          {linkData && (
            <Grid>
              <TextField
                value={linkData}
                label-="Payment Link"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={linkData}
                        onCopy={() =>
                          dispatch({
                            type: SNACKBAR_OPEN,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            transition: "SlideLeft",
                            open: true,
                            message: "Copied",
                            variant: "alert",
                            alertSeverity: "success",
                            close: false,
                          })
                        }
                      >
                        <Tooltip title="Copy">
                          <IconButton
                            aria-label="Copy from another element"
                            edge="end"
                          >
                            <ContentCopyTwoToneIcon
                              sx={{ fontSize: "1.1rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
         
            <Grid item alignItems={"center"} container>
              <Button
                disabled={
                  errorCode !== null || paymentAmount == 0 || orderDetails.paymentMethod !=1 ? true : false
                }
                variant="contained"
                onClick={createLink}
              >
                Create Link
              </Button>
            </Grid>
        </Grid>
      )}
    </SubCard>
  );
}

export default CreatePaymentLink;
