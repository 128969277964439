import axios from "axios";
import React, { useEffect } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";

function CreateOfflineCertificates() {
  const [allMeeting, setAllMeeting] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState([]);
  const [meetingId, setMeetingId] = React.useState([]);
  const getMeetings = () => {
    axios
      .get(`${process.env.REACT_APP_PATH}/seller/getMasterClass`)
      .then((res) => {
        console.log(res);
        if (res?.data?.success) {
          setAllMeeting(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCertificate = () => {
    axios
      .post(
        `${process.env.REACT_APP_PATH}/seller/addCertificateFormasterclass`,
        {
          phoneNumber: phoneNumber,
          meetingId: meetingId,
        }
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.success) {
          alert(res.data.message || "Certificate Created");
          setPhoneNumber("");
        } else {
          alert(res.data.message || "Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
      });
  };

  useEffect(() => {
    getMeetings();
  }, []);

  return (
    <SubCard>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} lg={4}>
          <Autocomplete
            options={allMeeting}
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            // style={{ width: "100%" }}
            onChange={(event, newValue) => {
              setMeetingId(newValue?._id || "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Meeting " variant="outlined" />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            label="Phone Number"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button onClick={createCertificate} variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default CreateOfflineCertificates;
