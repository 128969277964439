import React, { useEffect } from 'react'
import SubCard from '../../ui-component/cards/SubCard'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'
import axios from 'axios';
import MainCard from '../../ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

function NonCrmPackingOrder() {

    const tableNumber = localStorage.getItem('tableNumber')
    const [disableBtn, setDisableBtn] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [weight, setWeight] = useState('');
    const [date, setDate] = useState({
        startDate: moment().clone().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD')
    });
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([])

    const submitForm = (e) => {
        e.preventDefault();
        setDisableBtn(true);
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_PATH}/fulfilment/orders/orderPackeging`,
            data: {
                orderId: orderId,
                nonCrmOrder:true,
                weight:+weight
            }
        }).then(res => {
            if (res.data.headers?.success) {
                setDisableBtn(false);

                alert(res?.data?.headers?.message);
                setOrderId('');
                window.location.reload()


            } else {
                setDisableBtn(false);

                alert(res?.data?.headers?.message);


            }
        }).catch(err => {
            setDisableBtn(false);
            alert(err?.response?.data?.headers?.message)
        })
    }

    const dataLoad = () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_PATH}/fulfilment/orders/packersOrders`, {
            startDate: date.startDate,
            endDate: date.endDate,
            nonCrmOrder:true
        }).then(resp => {
            setLoading(false)
            console.log("response of fulfilment/orders/packersOrders", resp.data)
            if (resp.data.headers?.success){
            setList(resp.data.body)
        }else {
            alert(resp?.data?.headers?.message);
        }
        }).catch(err => {

            alert("err?.response?.data?.headers?.message")
        })
    }

    useEffect(()=>{
        dataLoad()
    },[])



    return (
        <>
            <SubCard>
                <form onSubmit={(e) => submitForm(e)}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} sm={4} xs={12}>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Table Number</InputLabel>
                                <Select
                                    value={tableNumber}
                                    label="Table Number"
                                    fullWidth
                                    defaultValue={tableNumber}
                                    disabled
                                >
                                    <MenuItem value={tableNumber}>{tableNumber}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} sm={4} xs={12}>
                            <TextField
                                label='Order Id'
                                required
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                                fullWidth
                            />

                        </Grid>
                        <Grid item lg={3} sm={4} xs={12}>
                            <TextField
                                label='Order Weight'
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                fullWidth
                                required
                            />

                        </Grid>
                        <Grid item lg={3} sm={4} xs={12}>
                            <Button variant='contained'
                                fullWidth

                                type='submit' disabled={disableBtn}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </SubCard>
            {/* --------------------------Packer list---------------------------------- */}
            <MainCard title="Packer Orders" >
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} lg={2}>
                        <TextField
                            id="datetime-local"
                            label="Packing Start Date"
                            type="date"
                            defaultValue={date.startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", }}
                            size="small"
                            onChange={(e) => setDate({ ...date, startDate: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            id="datetime-local"
                            label="Packing End Date"
                            type="date"
                            defaultValue={date.endDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                width: "100%",
                            }}
                            onChange={(e) => setDate({ ...date, endDate: e.target.value })}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <LoadingButton size="small" loading={loading}
                            onClick={dataLoad}
                            variant="contained" >Submit</LoadingButton>
                    </Grid>
                    {(loading) ?
                        <Typography style={{ marginTop: "20px" }} variant="h4" align="center">Loading....</Typography>
                        :
                        <TableContainer style={{ maxHeight: 440 }}>
                            {list.length > 0 &&
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Order Id</TableCell>
                                            <TableCell align="left">Packing Time</TableCell>
                                            <TableCell align="left">Weight</TableCell>
                                            {/* <TableCell align="left" >Group Order Id</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map((pack, idx) => {
                                            return (
                                                <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={pack?._id}>
                                                    <TableCell align="left" >{pack?.orderId}</TableCell>
                                                    {moment(pack?.packingTime).format('YYYY-MM-DD hh:mm:ss A')}  
                                                    {/* <TableCell align="left" >{pack?.groupOrderId ? pack?.groupOrderId : ""}</TableCell> */}
                                                    <TableCell align="left" >{pack?.weight}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    }
                </Grid>
            </MainCard>
        </>
    )
}

export default NonCrmPackingOrder