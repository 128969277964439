import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableHead,
  TextField,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { LoadingButton } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import SubCard from "../../ui-component/cards/SubCard";
import { TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { Link } from "react-router-dom";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
const bulkChildOrderHeader = [
  { label: "Name", key: "sellerName" },
  { label: "PhoneNumber", key: "phoneNumber" },
  { label: "Total Payable Amount", key: "payableAmount" },
  { label: "Booked At", key: "bookedAt" },
  { label: "Source", key: "sorce" },
  { label: "city", key: "city" },
  { label: "state", key: "state" },
];

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function AppointmentList() {
  const classes = useStyles();
  const agentType = getDecryptDataFromLocal("setRole");
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [pageLoad, togglePageLoad] = useState(false);

  const [allAppointmentData, setAllAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataLoad = async () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_PATH + "/appointments/getAllAppointmentForCrm",
        {
          startDate: date.startDate,
          endDate: date.endDate,
        }
      )
      .then((res) => {
        if (res.data.success) {
          setAllAppointmentData(res.data.data || []);
          togglePageLoad(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
      });
  };

  return (
    <>

   
    <SubCard title={"All Appointments"}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid xs={12} sm={12} md={6} lg={3} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            loadingIndicator="loading..."
            onClick={() => dataLoad()}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
        <Grid item lg={3} md={3} sm={12}>
          {!loading && allAppointmentData.length > 0 && (
            <CSVLink
              filename="Appointmentlist.csv"
              headers={bulkChildOrderHeader}
              data={allAppointmentData}
              title="Download data"
              style={{
                textDecoration: "none",
                border: "1px solid #999",
                padding: "10px 20px",
                backgroundColor: "#95c7f1",
              }}
            >
              Download data
            </CSVLink>
          )}
        </Grid>
      </Grid>
      </SubCard>
      <Grid style={{backgroundColor:'#fff',position:'sticky',top:0,marginTop:20}}>
        {
            allAppointmentData.length > 0 && (
                <TableContainer style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" style={{backgroundColor:'#fff'}}>
                  <TableHead >
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>PhoneNumber</TableCell>
                      <TableCell>Total Payable Amount</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {allAppointmentData &&
                      allAppointmentData.map((row) => (
                        <TableRow>
                          <TableCell>{row.sellerName}</TableCell>
                          <TableCell>{row.phoneNumber}</TableCell>
                          <TableCell>
                            {numericCommaSeparation(row.payableAmount)}
                          </TableCell>
                          <TableCell>
                            {moment(row.bookedAt).format("hh:mm a DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{row.source}</TableCell>
                          <TableCell>{row.city}</TableCell>
                          <TableCell>{row.state}</TableCell>
                          <TableCell>
                            <Link to={`/appointmentdetails/${row._id}`} target="_blank">
                              Details
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                </TableContainer>
            )
        }
      
        </Grid>
  
    </>
  );
}

export default AppointmentList;
