import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

const phoneNumber = getDecryptDataFromLocal('phoneNumber');
let OpsList = [];

if ([ "8851245596",'9667530528'].includes(phoneNumber)) {
  OpsList.push({
    id: "Lead Assign",
    title: <FormattedMessage id="Lead Assign" />,
    type: "item",
    url: "/ops/uploadnewleads",
  })
} else {
  OpsList.push(
    {
      id: "Create Spot Incentive",
      title: <FormattedMessage id="Create Spot Incentive" />,
      type: "item",
      url: "/ops/create-campaign",
    },
    {
      id: "Create Monthly Incentive",
      title: <FormattedMessage id="Create Monthly Incentive" />,
      type: "item",
      url: "/ops/create-monthly-incentive",
    },
    {
      id: "Lead Assign",
      title: <FormattedMessage id="Lead Assign" />,
      type: "item",
      url: "/ops/uploadnewleads",
    }
  )
}

export const Ops = {
  id: "ops",
  title: <FormattedMessage id="ops" />,
  type: "group",
  children: OpsList,
};
