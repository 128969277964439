import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Table, TableContainer } from "@material-ui/core";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import { LoadingButton } from "@material-ui/lab";
import { Link } from "react-router-dom";

function SearchBulkOrders() {
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const [customerName, setCustomerName] = React.useState("");
  const [InvoiceId, setInvoiceId] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [agentsData, setAgentsData] = React.useState([]);
  const [selectedAgents, setSelectedAgents] = React.useState([]);
  const [numericOrderId, setNumericOrderId] = React.useState("");
  const [data, setdata] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalpage, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);

  const checkStatus = (status, awbNumber) => {
    if (status == 1 && awbNumber) {
      return "In Transit";
    } else if (status === 1) {
      return "Processing";
    } else if (status === 2) {
      return "Cancelled";
    } else if (status === 3) {
      return "Delivered";
    } else if (status == 8) {
      return "Splited";
    } else if (status == 4) {
      return "RTO";
    } else return "";
  };

  const handleSearch = (cp = page, limit = rowsPerPage) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/getAllDetailedCrmBulkOrders`, {
        customerName,
        invoiceNumber: InvoiceId,
        phoneNumber,
        startDate: date.startDate,
        endDate: date.endDate,
        agentIds: selectedAgents.map((item) => item._id),
        numericOrderId,
        page: cp,
        limit: limit,
      })
      .then((res) => {
        if (res.data.success) {
          // console.log('res.data.data',res.data.data)
          let tempData = res.data.data[0]?.data;
          tempData.forEach((item) => {
            let totalDispatchAmount = 0;
            item.secondLevelChildren.forEach((item2) => {
              totalDispatchAmount += item2?.totalPayableAmount || 0;
            });
            item.firstLevelChildren.forEach((item1) => {
              item.secondLevelChildren.forEach((item2) => {
                if (item1.numericOrderId == item2.parentBulkNumericOrderId) {
                  if (item1["nextOrder"]) {
                    item1["nextOrder"].push(item2);
                  } else {
                    item1["nextOrder"] = [item2];
                  }
                }
              });
            });
            item.totalDispatchAmount = totalDispatchAmount;
          });
          setdata(tempData);
          setTotalPage(res.data.data[0].metaData[0]?.totalDocuments);
          setLoading(false);
        } else {
          alert(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    handleSearch("", +event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(rowsPerPage);
    handleSearch(newPage, rowsPerPage);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PATH}/admin/getBulkAgents`)
      .then((res) => {
        if (res.data.success) {
          if (res?.data?.data?.length > 0) {
            setAgentsData(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function CollapesRowForProducts({ order, mainOrder }) {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell></TableCell>
          {/* <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          {/* <TableCell>{mainOrder?.name || ""}</TableCell>
          <TableCell>{mainOrder?.phoneNumber || ""}</TableCell> */}
          <TableCell>{order?.numericOrderId || ""}</TableCell>
          <TableCell>
            {numericCommaSeparation(order?.totalPayableAmount || 0)}
          </TableCell>
          {/* <TableCell>{order?.orderStage || ""}</TableCell> */}
          <TableCell>{order?.zohoInvoice?.zohoInvoiceNumber || ""}</TableCell>
          <TableCell>
            {numericCommaSeparation(order?.zohoInvoice?.total || 0)}
          </TableCell>
          <TableCell>{order?.zohoInvoice?.creditNoteNumber || ""}</TableCell>
          <TableCell>{order?.awbNumber || ""}</TableCell>
          <TableCell>{checkStatus(order?.status, order?.awbNumber)}</TableCell>
          <TableCell>
            <Link
              to={`/bulkorderdetails/${order._id}`}
              style={{
                color: order.paymentMethod == 1 ? "#000" : "green",
              }}
              target="_blank"
            >
              Details
            </Link>
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order &&
                      order.products.map((product) => (
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.quantity}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
      </React.Fragment>
    );
  }
  function CollapesRowForNextOrder({ order, mainOrder, index }) {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {order.nextOrder && order.nextOrder.length > 0 ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{`order split ${index + 1}`}</TableCell>
          <TableCell>{mainOrder?.name || ""}</TableCell>
          <TableCell>{mainOrder?.phoneNumber || ""}</TableCell>
          <TableCell>{order?.numericOrderId || ""}</TableCell>
          <TableCell>
            {numericCommaSeparation(order?.totalPayableAmount || 0)}
          </TableCell>
          {/* <TableCell>{order?.orderStage || ""}</TableCell> */}
          {/* <TableCell>{checkStatus(order?.status)}</TableCell> */}
        </TableRow>

        <TableRow style={{ backgroundColor: "#fef6f5" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      Order {index + 1} Dispatched Summary
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Total PayableAmount</TableCell>
                    {/* <TableCell>Order Stage</TableCell> */}
                    <TableCell>Invoice Id</TableCell>
                    <TableCell>Invoice Total</TableCell>
                    <TableCell>Cn Number</TableCell>
                    <TableCell>AWB Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.nextOrder &&
                    order.nextOrder.map((item) => {
                      return (
                        <CollapesRowForProducts
                          order={item}
                          mainOrder={order}
                        />
                      );
                    })}
                </TableBody>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function CollapesRowForOrder({ order }) {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {order.firstLevelChildren.length > 0 ||
          order.secondLevelChildren.length > 0 ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <TableCell></TableCell>
          )}

          <TableCell>{order?.name || ""}</TableCell>
          <TableCell>{order?.phoneNumber || ""}</TableCell>
          <TableCell>
            {moment(order?.createdAt).format("hh:mm a DD-MMM-YYYY")}
          </TableCell>
          <TableCell>
            {numericCommaSeparation(order?.totalPayableAmount || 0)}
          </TableCell>
          <TableCell>
            {numericCommaSeparation(order?.totalDispatchAmount || 0)}
          </TableCell>
          {/* <TableCell>{order?.orderStage || ""}</TableCell> */}
          {/* <TableCell>{checkStatus(order?.status)}</TableCell> */}
          <TableCell></TableCell>
        </TableRow>

        <TableRow style={{ backgroundColor: "#e7e4e4" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {order.firstLevelChildren.length > 0 ? (
                <Box>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Order </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Total PayableAmount</TableCell>
                      {/* <TableCell>Order Stage</TableCell> */}
                      {/* <TableCell>Status</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.firstLevelChildren &&
                      order.firstLevelChildren.map((item, index) => {
                        return (
                          <CollapesRowForNextOrder
                            order={item}
                            mainOrder={order}
                            level="First"
                            index={index}
                          />
                        );
                      })}
                  </TableBody>
                </Box>
              ) : (
                order.secondLevelChildren.length > 0 && (
                  <Box>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Order Id</TableCell>
                        <TableCell>Total PayableAmount</TableCell>
                        <TableCell>Invoice Id</TableCell>
                        <TableCell>Invoice Total</TableCell>
                        <TableCell>Cn Number</TableCell>
                        <TableCell>AWB Number</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.secondLevelChildren &&
                        order.secondLevelChildren.map((item) => {
                          return (
                            <CollapesRowForProducts
                              order={item}
                              mainOrder={order}
                              level="Second"
                            />
                          );
                        })}
                    </TableBody>
                  </Box>
                )
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <SubCard>
      <Grid container spacing={2}>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container lg={12} spacing={2} mt={2} alignItems={"center"}>
        <Grid item lg={3} md={3}>
          <TextField
            label="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Invoice Id"
            value={InvoiceId}
            onChange={(e) => setInvoiceId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Birth Order Id"
            value={numericOrderId}
            onChange={(e) => setNumericOrderId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <Autocomplete
            id="tags-outlined"
            options={agentsData}
            fullWidth
            multiple
            getOptionLabel={(option) => `${option.name} (${option.userName})`}
            onChange={(e, v) => setSelectedAgents(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Agents"
                placeholder="Select Agents"
              />
            )}
            value={selectedAgents}
          />
        </Grid>
        <Grid item lg={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSearch(page, rowsPerPage)}
            fullWidth
            loading={loading}
            loadingIndicator="Loading..."
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <TableContainer style={{ maxHeight: 440, marginTop: 20 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Total PayableAmount</TableCell>
              <TableCell>Total Dispatch Amount</TableCell>
              {/* <TableCell>Order Stage</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item, idx) => {
                return <CollapesRowForOrder order={item} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalpage ? totalpage : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </SubCard>
  );
}

export default SearchBulkOrders;
