import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, TextField, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const OtpModal = ({ open, handleClose, handleVerify,phoneNumber='' }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  useEffect(() => {
    // Focus on the first input when the modal opens
    // if (open) {
    //   inputRefs.current[0].focus();
    // }
  }, [open]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Move to the next input if a digit is entered
      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      // Move to the previous input if backspace is pressed
      if (index > 0 && newOtp[index] === "") {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleSubmit = () => {
    handleVerify(otp.join(""));
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          zIndex: 100,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Enter OTP (sent to {phoneNumber})
        </Typography>
        <Grid container spacing={1}>
          {otp.map((_, index) => (
            <Grid item xs={3} key={index}>
              <TextField
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                }}
                value={otp[index]}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyPress(e, index)}
                inputRef={(el) => (inputRefs.current[index] = el)}
              />
            </Grid>
          ))}
        </Grid>
        <button
          style={{
            backgroundColor: 'transparent',
            position: "absolute",
            right: 5,
            top:5,
            borderRadius:15,
            height:30,
            width:30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon color="#000" />
        </button>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default OtpModal;
