import { Button, ListItem, ListItemText, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';

function TicketInfo() {

    const [ticketInfo, setTicketInfo] = useState([]);


    const setFreshColor = (ticketInfo) => {
        if (ticketInfo[0]?.freshTickets >= 3) {
            return 'error'
        } else {
            return 'success'
        }
    }

    const setPendingColor = (ticketInfo) => {
        if (ticketInfo[0]?.pendingTickets >= 6) {
            return 'error'
        } else {
            return 'success'
        }
    }

    const setBGColor = (ticketInfo) => {
        if (ticketInfo[0]?.freshTickets >= 3 || ticketInfo[0]?.pendingTickets >= 6) {
            return 'error'
        } else {
            return 'success'
        }
    }


    useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_PATH}/role5/getMyTickets`
        }).then(res => {
            if (res.data.success) {
                setTicketInfo(res?.data?.data);
            } else {
                alert(res.data.message)
            }

        }).catch(err => {
            alert(err.response.data.message)
        })
    }, []);
    return (
        <div>
            <ListItem>
                <Button
                    id="fade-button"

                    aria-haspopup="true"

                    color={setFreshColor(ticketInfo)}
                    variant='outlined'
                >
                    <Typography style={{ color: setBGColor(ticketInfo), padding: "5px", fontWeight: '600' }}>New Tickets: {ticketInfo[0]?.freshTickets}</Typography>
                </Button>
                <Button
                    id="fade-button"

                    aria-haspopup="true"
                    style={{ marginLeft: "1rem" }}
                    color={setPendingColor(ticketInfo)}
                    variant='outlined'
                >
                    <Typography style={{ color: setBGColor(ticketInfo), padding: "5px", fontWeight: '600' }}>Pending Tickets: {ticketInfo[0]?.pendingTickets}</Typography>
                </Button>


            </ListItem>

        </div>
    )
}

export default TicketInfo